<script lang="ts" setup>
import { ref, computed, watch, onMounted, onBeforeUnmount } from "vue";
import { finishChunkedFile, getUploadStatus, complexesToCll, severityCode } from "@/services/api/asset.api";
import useDateTimeFormatter from "@/composables/formatDateTime.js";
import { useAuthStore } from "@/stores/auth";

const { formatDateUtcDT } = useDateTimeFormatter();
const authStore = useAuthStore();

const currentStep = ref(3);
const copyToStorage = ref([]);
const copyToStorageError = ref(undefined);
const updateTimer = ref(undefined);
const status = ref(undefined);
const partyName = ref([]);
const partyNamePushed = ref([]);

const uploadUrl = computed(() => {
  return (process.env.VUE_APP_PORTAL_URL ? process.env.VUE_APP_PORTAL_URL : "/") + "api/asset/chunk";
});
const statusImport = computed(() => {
  if (status.value) {
    return status.value.filter((x) => x.importCllStatus !== "None");
  } else {
    return undefined;
  }
});
const lastImportExcelStatus = computed(() => {
  if (status.value && status.value.length > 0) {
    return status.value[0].importExcelStatus === "Succes";
  } else {
    return false;
  }
});

watch(
  () => lastImportExcelStatus.value,
  (newValue, oldValue) => {
    //kijk of status import veranderde van niet naar succes
    if (!oldValue && newValue) {
      currentStep.value = 3;
    }
  }
);

watch(
  () => currentStep.value,
  () => {
    getUploads();
  }
);

onMounted(() => {
  setToken();
  getUploads();
  updateTimer.value = setInterval(() => {
    getUploads();
  }, 10000);
});

onBeforeUnmount(() => {
  clearInterval(updateTimer.value);
});

const importVabiDropzone = ref(null);

async function setToken() {
  // Authorization header kan hier pas gezet worden als deze in de template zet gaat ie verloren in de init van dropzone
  const token = await authStore.getAccessToken();

  //console.log("Import mounted token", token);
  importVabiDropzone.value.dropzone.options.headers = { Authorization: `Bearer ${token}` };
}

async function onUploaded(file) {
  console.log("ImportVabi onUploaded, file: ", file, file.upload);
  copyToStorage.value.push(file.upload.uuid);
  const response = await finishChunkedFile(file.upload.uuid, file.upload.filename, file.upload.chunks.length);
  console.log("ImportVabi onUploaded, response: ", response);
  if (response.severity <= severityCode.warning) {
    copyToStorage.value = copyToStorage.value.filter((item) => item !== file.upload.uuid);
    if (copyToStorage.value.length == 0) {
      currentStep.value = 2;
    }
    //alert(`Bestand is correct ontvangen`);
  } else {
    copyToStorageError.value = true;
  }
}

function onUploadError(file, message, xhr) {
  console.log("ImportVabi onUploadError, file, xhr: ", file, xhr);
  console.log("ImportVabi Message ====> " + JSON.stringify(message));
}

async function getUploads() {
  const response = await getUploadStatus(5);
  console.log("ImportVabi getUploads: ", response);
  status.value = response.data;

  //testing:
  // status.value[0].importCllStatus="Busy";
  // status.value[0].complexesTotal=5;
  // status.value[0].complexesNumber=1;
  // status.value[0].housesTotal=10;
  // status.value[0].housesNumber=11;
}

function message(id) {
  switch (id) {
    case 0:
      return "-";
    case 1011:
      return "Missende tab: ";
    case 1012:
      return "Missende verplichte kolom: ";
    case 1013:
      return "Missende optionele kolom: ";
    case 1031:
      return "Geen VHE";
    case 1032:
      return "Geen bouwjaar";
    case 1033:
      return "Dubbele PHTD referentie";
    case 1034:
      return "Dubbel VHE referentie";
    case 1035:
      return "Ongeldige BAG gegevens";
    case 3001:
      return "Ongeldige partij";
    case 3004:
      return "BAG locatie niet in pandenlijst";
    default:
      return `${id}`;
  }
}

function checkPartyNameInput(importId) {
  const str = partyName.value[importId];
  const done = partyNamePushed.value[importId];
  return str && str.trim() !== "" && !done;
}

async function pushImport(importId) {
  const str = partyName.value[importId];
  partyNamePushed.value[importId] = true;
  await complexesToCll(importId, "Woningen", str);
  return importId;
}
</script>

<template>
  <v-container fluid class="app-overview">
    <header class="app-welcome-message">
      <h1>Vabi Excel importeren</h1>
    </header>
    <v-container fluid py-0>
      <v-stepper v-model="currentStep" vertical>
        <v-stepper-step :complete="currentStep > 1" step="1" editable> Bestand aanleveren </v-stepper-step>

        <v-stepper-content step="1">
          <v-layout class="d-flex flex-column form-group">
            <small>Lever de Vabi Excel aan</small>
            <v-alert outlined type="warning">Controleer of alle VHE nummers gevuld zijn voordat u aan de upload begint.</v-alert>
            <vue-dropzone
              id="import-vabi-dropzone"
              ref="importVabiDropzone"
              v-on:vdropzone-success="onUploaded"
              v-on:vdropzone-error="onUploadError"
              :options="{
                url: uploadUrl,
                acceptedFiles: '.xlsx,.exe,.msi,.zip',
                maxFilesize: 150000000,
                maxFiles: 10,
                disablePreviews: true,
                chunking: true,
                forceChunking: true,
                thumbnailHeight: 200,
                addRemoveLinks: true,
                dictDefaultMessage: 'Sleep een bestand hier naar toe of klik om te uploaden.',
                dictFileTooBig: 'Het bestand is te groot ({{filesize}}MiB). Maximale bestandsgrootte: {{maxFilesize}}MiB.',
                dictInvalidFileType: 'Het is niet mogelijk bestanden van dit type te uploaden (toegestaan: .xlsx).',
                dictResponseError: 'De Server reageerde met code {{statusCode}}.',
                dictRemoveFile: 'Verwijder bestand',
                dictMaxFilesExceeded: 'U kunt maximaal 1 bestand uploaden.',
              }"
            ></vue-dropzone>
          </v-layout>
          <v-layout v-if="copyToStorage.length > 0">Bestand wordt klaargezet in de cloud<span class="loadingDots"></span> </v-layout>
          <v-alert v-if="copyToStorageError" type="error">Probleem bij het uploaden.</v-alert>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 2" step="2" editable> Import ruwe data </v-stepper-step>
        <v-stepper-content step="2">
          <v-container v-if="status" fluid>
            <v-row>
              <v-col cols="2"> Tijdstip </v-col>
              <v-col cols="3">Bestand</v-col>
              <v-col>Resultaat</v-col>
            </v-row>
            <template v-for="(item, index) in status">
              <v-row :key="index">
                <v-col cols="2"> {{ formatDateUtcDT(item.uploadDate) }} </v-col>
                <v-col cols="3">{{ item.filename }} </v-col>
                <v-col>
                  <v-container fluid pa-0>
                    <v-row dense>
                      <v-col cols="2">Upload</v-col>
                      <v-col>
                        <v-icon v-if="item.uploadStatus == 'Success'" color="green">mdi-check</v-icon>
                        <v-icon v-else-if="item.uploadStatus == 'None'">mdi-tilde</v-icon>
                        <v-icon v-else>mdi-close-octagon-outline</v-icon>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="2">Controle</v-col>
                      <v-col>
                        <v-icon v-if="item.excelStatus == 'Success'" color="green">mdi-check</v-icon>
                        <template v-else-if="item.excelStatus == 'Busy'">
                          <v-icon>mdi-timer-sand</v-icon>
                          Bezig met controleren <span class="loadingDots"></span>
                        </template>
                        <template v-else-if="item.excelStatus == 'Warning'">
                          <v-icon color="orange">mdi-alert-outline</v-icon>
                          <v-row v-for="(err, index2) in item.excelErrors" :key="index2" dense>
                            <v-col cols="auto">
                              {{ message(err.id) }}
                            </v-col>
                            <v-col>{{ err.name }}</v-col>
                          </v-row>
                        </template>
                        <template v-else-if="item.excelStatus == 'Failed'">
                          <v-icon color="red">mdi-close-octagon-outline</v-icon>
                          <v-row v-for="(err, index2) in item.excelErrors" :key="index2" dense>
                            <v-col cols="auto">
                              {{ message(err.id) }}
                            </v-col>
                            <v-col>{{ err.name }}</v-col>
                          </v-row>
                        </template>
                        <v-icon v-else>mdi-tilde</v-icon>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col cols="2">Import</v-col>
                      <v-col>
                        <template v-if="item.importExcelStatus == 'Success'">
                          <v-icon color="green">mdi-check</v-icon>
                        </template>
                        <template v-else-if="item.importExcelStatus == 'Busy'">
                          <v-icon>mdi-timer-sand</v-icon>
                          Bezig met inlezen <span v-if="item.excelStatusProgress">'{{ item.excelStatusProgress }}'</span><span class="loadingDots"></span>
                        </template>
                        <template v-else-if="item.importExcelStatus == 'Failed'">
                          <v-icon color="red">mdi-close-octagon-outline</v-icon>
                        </template>
                        <v-icon v-else>mdi-tilde</v-icon>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-divider :key="`sel-div-${index}`"></v-divider>
            </template>
          </v-container>
        </v-stepper-content>

        <v-stepper-step :complete="currentStep > 3" step="3" editable> Gegevens overbrengen naar pandenlijst </v-stepper-step>
        <v-stepper-content step="3">
          <v-container v-if="status" fluid>
            <v-row>
              <v-col cols="2"> Tijdstip </v-col>
              <v-col cols="3">Bestand</v-col>
              <v-col cols="4">Partijnaam</v-col>
              <v-col>Resultaat</v-col>
            </v-row>
            <template v-for="(item, index) in statusImport">
              <v-row :key="index">
                <v-col cols="2"> {{ formatDateUtcDT(item.importDate) }} </v-col>
                <v-col cols="3">{{ item.filename }} </v-col>
                <v-col cols="">
                  <template v-if="item.importCllStatus == 'WaitForInput'">
                    <v-container fluid pa-0>
                      <v-row>
                        <v-col class="d-flex flex-nowrap">Woningen:&nbsp;<v-text-field hide-details="auto" outlined dense v-model="partyName[item.importId]" class="noBorders"></v-text-field> </v-col>
                        <v-col>
                          <v-btn class="primary" :disabled="!checkPartyNameInput(item.importId)" v-on:click="pushImport(item.importId)">
                            <v-icon dark left>mdi-pencil-circle</v-icon>
                            Verwerken
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-container>
                  </template>
                  <span v-else>{{ item.partyName }}</span>
                  <!-- :readonly="!inEdit(item.id)" -->
                  <!-- :error-messages="errors" -->
                </v-col>
                <v-col>
                  <template v-if="item.importCllStatus == 'Success'">
                    <v-icon color="green">mdi-check</v-icon>
                    <span v-if="item.complexesTotal && !item.complexesNumber && item.housesTotal && !item.housesNumber">{{ item.complexesTotal }} complex(en) - {{ item.housesTotal }} woning(en)</span>
                  </template>
                  <template v-else-if="item.importCllStatus == 'WaitForInput'">
                    <v-icon>mdi-timer-sand-paused</v-icon>
                    Wacht op input<span class="loadingDots"></span>
                  </template>
                  <template v-else-if="item.importCllStatus == 'Busy'">
                    <v-icon>mdi-timer-sand</v-icon>
                    Bezig met inlezen<span class="loadingDots"></span>
                    <div v-if="item.complexesTotal">
                      complex {{ item.complexesNumber }}/{{ item.complexesTotal }}
                      <span v-if="item.housesTotal && item.housesTotal > 0 && item.housesNumber <= item.housesTotal">, woning {{ item.housesNumber }}/{{ item.housesTotal }}</span>
                      <span v-else-if="item.housesTotal && item.housesNumber > item.housesTotal">, afronden</span>
                    </div>
                  </template>
                  <template v-else-if="item.importCllStatus == 'Failed'">
                    <v-icon color="red">mdi-close-octagon-outline</v-icon>
                    <span>{{ item.complexesTotal }} complex(en) - {{ item.housesTotal }} woning(en)</span>
                  </template>
                  <v-icon v-else>mdi-tilde</v-icon>
                </v-col>
              </v-row>
              <template if="item.importCllStatus == 'Failed'">
                <v-row v-for="(err, index3) in item.importErrors" :key="`importErrors-${index3}`" dense>
                  <v-col cols="1"></v-col>
                  <v-col>{{ err.reference }}</v-col>
                  <v-col cols="auto">:</v-col>
                  <v-col>
                    {{ message(err.messageId) }}
                  </v-col>
                </v-row>
              </template>
              <v-divider :key="`sel-div-${index}`"></v-divider>
            </template>
          </v-container>
        </v-stepper-content>
      </v-stepper>
    </v-container>
    <v-container fluid py-0>
      <v-row>
        <v-col></v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
